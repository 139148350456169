import { AppRoutes } from "src/Components/Constant/routes";
import { HomeWrapper } from "./styles";
import CustomTabs from "src/Components/Shared/Tabmenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { JobsAction } from "src/Redux/App/Actions/Jobs";
const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location: any = useLocation();
  const record = location.state && location.state;
  useEffect(() => {
     console.log(localStorage.getItem('key'),"localStorage.getItem('key')")
    if (localStorage.getItem("token") ) {
      navigate(AppRoutes.home);
      if(localStorage.getItem('key') == '1'){
      dispatch<any>(JobsAction());

      }
    } else {
      navigate(AppRoutes.login);
    }
  }, [1000])
  return (
    <>
      {
        localStorage.getItem("token") ?
          <HomeWrapper>
            <CustomTabs />
          </HomeWrapper> : 
          <></>
      }
    </>

  );
};

export default Home;
