import moment from 'moment';
import StatusFailed from "src/Assets/Icons/StatusFailed";
import StatusSuccess from "src/Assets/Icons/StatusSuccess";

export const data = [
  {
    "First Name": "Woong",
    "Last Name": "Kim",
    Email: "wkim@kimleelaw.com",
    "Company Name": "Hispanic Business Solutions Group, LLC",
    "Phone Number": "619 293 3600",
    Created: "2006-07-21",
  },
  {
    "First Name": "Jong-Joon",
    "Last Name": "Chun",
    Email: "jjchun2000@yahoo.com",
    "Company Name": "Jade Century Properties Inc.",
    "Phone Number": "(703) 914-155",
    Created: "2006-07-27",
  },
  {
    "First Name": "Carolyn",
    "Last Name": "Brooks",
    Email: "carlyn.books@misyshealthcare.com",
    "Company Name": "Art Partner, Inc.",
    "Phone Number": "520-570- 2428",
    Created: "2006-07-28",
  },
  {
    "First Name": "Charles",
    "Last Name": "Goldsmith",
    Email: "tgoldsmith@ggw.com",
    "Company Name": "G.C. Consultant, Inc.",
    "Phone Number": "212-643-6400",
    Created: "2006-08-01",
  },
  {
    "First Name": "Darren",
    "Last Name": "Silver",
    Email: "ronaldquilon@darrensilver.com",
    "Company Name": "Cigna-Evernorth Services Inc.",
    "Phone Number": "213-384-1900",
    Created: "2006-08-03",
  },
  {
    "First Name": "Don",
    "Last Name": "Gonzalez",
    Email: "dongonzalez@ggw.com",
    "Company Name": "Christiana Care Health Services, Inc.",
    "Phone Number": "954-5980660",
    Created: "2006-08-10",
  },
  {
    "First Name": "Margarita",
    "Last Name": "Koutsis",
    Email: "gscastro@byridimmigration.com",
    "Company Name": "Southeastern Physician Services, PC",
    "Phone Number": "(310) 899 0818",
    Created: "2006-08-11",
  },
  {
    "First Name": "Russell",
    "Last Name": "Swapp",
    Email: "ahebert@seyfarth.com",
    "Company Name": "Certified Anesthesia Services, PLLC",
    "Phone Number": "617-946-4905",
    Created: "2006-08-25",
  },
  {
    "First Name": "Yanan",
    "Last Name": "Liu",
    Email: "weiran.liu@gmail.com",
    "Company Name": "Northeast Arkansas Clinic Charitable Foundation, Inc",
    "Phone Number": "718-753-8166",
    Created: "2006-09-07",
  },
  {
    "First Name": "Jane",
    "Last Name": "Chung",
    Email: "info@janechuglaw.com",
    "Company Name": "The Flight School of Gwinnett, Inc.",
    "Phone Number": "213-738-0928",
    Created: "2006-09-08",
  },
  {
    "First Name": "Michelle",
    "Last Name": "Ramos",
    Email: "workvisas@msn.com",
    "Company Name": "Firmenich Incorporated",
    "Phone Number": "954-975-4823",
    Created: "2006-11-09",
  },
  {
    "First Name": "Jan",
    "Last Name": "Brown",
    Email: "dana@jahnbrown.com",
    "Company Name": "Cigna-Evernorth Services Inc.",
    "Phone Number": "212-397-7376",
    Created: "2006-13-09",
  },
  {
    "First Name": "Kathryn",
    "Last Name": "Healthcock",
    Email: "hhfirm@gamil.com",
    "Company Name": "Motional AD Inc.",
    "Phone Number": "954-975-4823",
    Created: "2006-18-09",
  },
  {
    "First Name": "Illya",
    "Last Name": "Drunov",
    Email: "support@eboris.com",
    "Company Name": "Tera Nix DDS PA",
    "Phone Number": "212-397-7376",
    Created: "2006-18-09",
  },
  {
    "First Name": "George",
    "Last Name": "Mann",
    Email: "orodriguesz@visalaw.com",
    "Company Name": "Simplisafe, Inc.",
    "Phone Number": "248-932-4971",
    Created: "2006-26-09",
  },
  {
    "First Name": "David",
    "Last Name": "Jones",
    Email: "mtorres@visalaw.com",
    "Company Name": "Arwen Nicolas Inc.",
    "Phone Number": "901-682-6394",
    Created: "2006-26-08",
  },
  {
    "First Name": "Sabrina",
    "Last Name": "Mojica",
    Email: "yl@doctorimmigration.com",
    "Company Name": "VistaTech LLC d/b/a FlyXO",
    "Phone Number": "212-334-5304",
    Created: "2006-09-07",
  },
  {
    "First Name": "Polina",
    "Last Name": "Kropacheva",
    Email: "pkropacheva@bdl-law.com",
    "Company Name": "VistaTech LLC d/b/a FlyXO",
    "Phone Number": "901-682-5105",
    Created: "2006-09-08",
  },
  {
    "First Name": "Nora",
    "Last Name": "Duran",
    Email: "nduran@chemonics.com",
    "Company Name": "Taad Group, Inc.",
    "Phone Number": "202-955-3400",
    Created: "2006-09-07",
  },
  {
    "First Name": "Orie",
    "Last Name": "Ozawa",
    Email: "oozawa@hazamaco.com",
    "Company Name": "Sang Eun Lee DDS Inc.",
    "Phone Number": "901-682-5105",
    Created: "2006-09-08",
  },
  {
    "First Name": "Candelaria",
    "Last Name": "Cordero",
    Email: "ins@pros.com",
    "Company Name": "Upway USA Inc.",
    "Phone Number": "954-392-5661",
    Created: "2006-09-07",
  },
  {
    "First Name": "Paul D.",
    "Last Name": "Cass",
    Email: "pdcesq@sprynet.com; pdces@gmail.com",
    "Company Name": "Zonin USA Inc.",
    "Phone Number": "213-380-8143",
    Created: "2006-09-08",
  },
  {
    "First Name": "Rhoda",
    "Last Name": "Pflum",
    Email: "rpflum@crawlaw.us",
    "Company Name": "Bracebridge Capital, LLC",
    "Phone Number": "954-392-5661",
    Created: "2006-09-07",
  },
  {
    "First Name": "Layli",
    "Last Name": "Eskandari",
    Email: "layli@leimmigration.com",
    "Company Name": "Verition Group, LLC",
    "Phone Number": "678-265-8680",
    Created: "2006-09-08",
  },
  {
    "First Name": "Lynette",
    "Last Name": "Duffel",
    Email: "lynette.duffel@reedbusiness.com",
    "Company Name": "Novozymes North America, Inc.",
    "Phone Number": "954-392-5661",
    Created: "2006-09-07",
  },
  {
    "First Name": "Layli",
    "Last Name": "Eskandari",
    Email: "layli@leimmigration.com",
    "Company Name": "Verition Group, LLC",
    "Phone Number": "678-265-8680",
    Created: "2006-09-08",
  },
  {
    "First Name": "Lynette",
    "Last Name": "Duffel",
    Email: "lynette.duffel@reedbusiness.com",
    "Company Name": "Novozymes North America, Inc.",
    "Phone Number": "954-392-5661",
    Created: "2006-09-07",
  },
];
export const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    width: 120,
    sorter: true, 
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    width: 120,
    sorter: true, 
  },
  {
    title: "Email",
    dataIndex: "users_email",
    key: "users_email",
    width: 200,
    sorter: true, 
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
    width: 300,
    sorter: true, 
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
    width: 150,
    sorter: true, 
  },
  {
    title: "Created",
    dataIndex: "registerDate",
    key: "registerDate",
    width: 100,
    sorter: true, 
    render: (text: any, object: any) => {
      const date:any = moment(object.registerDate).format("MM-DD-YYYY"); 
      return <div>{date ? date.slice(0, 10) : ''}</div>;
    },
  },
];
