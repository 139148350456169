import axios, { AxiosResponse } from "axios";
import { requestStart, requestCompleted, requestSuccess } from "../..";
import { message, notification } from "antd";
import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { AppRoutes } from "src/Components/Constant/routes";

// export function LoginAction(
//   data:any,
//   navigate: NavigateFunction
// ) {
//   return async (dispatch: Dispatch) => {
//     // dispatch(requestStart());
//     // var bodyFormData = new FormData();
//     // bodyFormData.append("username", data.username);
//     // bodyFormData.append("password", data.password);

//     try {
//       axios
//         .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data)
//         .then((res: AxiosResponse) => {
//           if (res) {
//             // localStorage.setItem("token", res.data.access_token);
//             // navigate("/");
//             dispatch(requestCompleted());
//             // notification.success({
//             //   message: "Login successfully",
//             // });
//           }
//         })
//         .catch((error) => {
//           dispatch(requestCompleted());
//           message.error(
//             // message: error?.response?.data?.message,
//             "Username or password does not match"
//           );
//           console.log(error?.response?.data?.message);
//         });
//     } catch (error) {
//       dispatch(requestCompleted());
//     }
//   };
// }


export function LoginAction(
  data: { users_email: string; users_password: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data)
        .then((res: AxiosResponse) => {
          if (res && res.data.accessToken) {
            if (res.data.user.role === 'ADMIN') {
              localStorage.setItem("token", res.data.accessToken);
              localStorage.setItem("role", res.data.user.role);
              console.log(res.data.user.quickBookUserId,"res.data.user.quickBookUserId")
              localStorage.setItem("customerId", res.data.user.quickBookUserId);
              navigate(AppRoutes.home);
              dispatch(requestCompleted());
              notification.success({
                message: "Login successful",
              });
            } else {
              dispatch(requestCompleted());
              notification.error({
                message: "Email or password does not match",
              });
            }

          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: "Email or password does not match",
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function ForgetPasswordAction(
  data: { users_email: string, redirectUrl: any },
  notify: () => void
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/forget-password`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            notify()
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetPasswordAction(
  data: { users_password: string },
  token: string,
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .patch(
          `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            navigate(AppRoutes.login);
            notification.success({
              message: "Password updated successfully",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function CustomerAction() {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/user/list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            console.log(res.data.data)
            dispatch(requestSuccess(res.data.data));
          }
        })
        .catch((error) => {

          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function EditCustomerAction(navigate: any, body: any, id: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    delete body.id
    try {
      axios
        .patch(`${process.env.REACT_APP_API_BASE_URL}/auth/edit-customer?id=${id}`, body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res && res.data) {
            navigate('/', { state: { tab: '3' } });
            localStorage.removeItem('add')
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}


export function SortCustomerAction(field: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem('token');
      console.log(field, "field.key")
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/sorted/list?sortBy=${field.key}&sortOrder=${field.order}&keyword=${field.keyword}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        console.log(response.data.data);
        dispatch(requestSuccess(response.data.data));
      }
    } catch (error: any) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.message,
      });
    }
  };

}


export function SearchCustomerAction(keyword: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/search/list?keyword=${keyword}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        dispatch(requestSuccess(response.data.data));
      }
    } catch (error: any) {
      dispatch(requestCompleted());
      notification.error({
        message: error?.response?.data?.message,
      });
    }
  };

}