import { Button } from "antd";
import { StatusButtonWraper } from "./styles";
import { Link } from "react-router-dom";

const StatusButton = ({ children, status }: any) => {
  return (
    <StatusButtonWraper>
      {/* <Link to={to}> */}
      <Button
        className={
          status === "Pending" ? "pending-button" : "processing-button "
        }
      >
        {status}
      </Button>
      {/* </Link> */}
    </StatusButtonWraper>
  );
};

export default StatusButton;
