import styled from "styled-components";

export const ParentWrapper = styled.div`
.ant-spin .ant-spin-dot-item{
  background-color: #FF9900 !important;
}

  .search-butn {
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    color: red;
    padding: 11.2px 22px;
    border: 1px solid #c1bfbc;
    border-radius: 4px;
    box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
    height: fit-content;
    line-height: 16px;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      color: #FF9900 !important;
      border: 1px solid #FF9900 !important;
    }
    &:focus {
      color: #FF9900 !important;
      border: 1px solid #FF9900 !important;
    }
  }

`;
export const GridWraper = styled.div`
  padding: 16px 72px 40px;
  .error-icon {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #78736d !important;
    font-family: "NunitoSansLight";
    margin-top: 4px;
    margin-bottom: 6px;
    line-height: 0px;
  }
  .print-icon {
    cursor: pointer;
  }
`;
export const SearchFormWraper = styled.div`
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  /////date picker/////
  .ant-picker-outlined {
    border-radius: 4px;
    border: 1px solid rgb(193, 191, 188);
    :hover {
      border: 1px solid ${(props) => props.theme.colors.brown_200};
    }
  }
  ////////affix //////////

  .ant-input-affix-wrapper {
    padding: 8.2px 12px;
    background: ${(props) => props.theme.colors.black_100};
    border: 1px solid #c1bfbc;
    height: 40.5px;
    max-width: 248px !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ffad33;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0px 0px;
    background: transparent;
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
  input[type="search"]::placeholder {
    color: ${(props) => props.theme.colors.black_1000};
    font-size: 14px;
    font-weight: 600;
    font-family: "NunitoSansBold";
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 8px;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0px;
  }
  .ant-form-item .ant-form-item-label > label {
    font-family: "NunitoSansLight";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin: 3px 8px 0px 0px;
  }
  .ant-input {
    padding: 9.2px 12px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.black_400};
    line-height: 20px;
    width: 248px;
    background: ${(props) => props.theme.colors.black_100};
  }
  .ant-input:hover {
    border: 1px solid #ffad33;
  }
  .ant-input:focus {
    border: 1px solid #c1bfbc;
    color: #1a1a1a;
  }
  .ant-input-status-success {
    border: 1px solid #b56d00;
    color: #1a1a1a;
  }

  .search-butn {
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black_1000};
    padding: 11.2px 22px;
    border: 1px solid #c1bfbc;
    border-radius: 4px;
    box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
    height: fit-content;
    line-height: 16px;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      color: ${(props) => props.theme.colors.brown_200} !important;
      border: 1px solid ${(props) => props.theme.colors.brown_200} !important;
      background-color: 1px solid ${(props) => props.theme.colors.brown_200} !important;
    }
    &:focus {
      color: ${(props) => props.theme.colors.brown_200} !important;
      border: 1px solid ${(props) => props.theme.colors.brown_200} !important;
    }
  }
`;
export const TableWraper = styled.div`

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
    background: ${(props) => props.theme.colors.black_200};
    font-family: "PopinsMedium";
    font-size: 14px;
    line-height: 16.8px;
    padding: 12px 0px 12px 16px;
    font-weight:800 !important;
    color: ${(props) => props.theme.colors.black_1000};
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-family: "PopinsRegular"; 
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 9.5px 0px 9.5px 16px;
    color: ${(props) => props.theme.colors.black_1000};
    border-bottom: 1px solid ${(props) => props.theme.colors.black_400};
  }
  td {
    &.ant-table-cell.job-number-column {
      color: ${(props) => props.theme.colors.blue} !important;
      font-weight: 400 !important;
      &a {

      }
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px;
  }
  .ant-table-container table > tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 0px;
  }
  .ant-table-container table > tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 0px;
  }

  .table-row-White {
    background: ${(props) => props.theme.colors.white};
    cursor:default;
  }

  .table-row-White-customer {
    background: ${(props) => props.theme.colors.white};
    cursor:pointer;
  }
  .table-row-lite {
    background: #f2f1f04d;
    cursor:default;
  }
  .table-row-lite-customer {
    background: #f2f1f04d;
    cursor:pointer;
  }
  .ant-pagination {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .ant-pagination-item,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-prev,
  .ant-pagination-next {
    order: 2;
  }

  .ant-pagination-options {
    order: 1;
    margin-right: 10px; /* Adjust as needed */
    border:1px solid :#eeeeee;
  }

  .ant-pagination-total-text {
    font-family: "NunitoSansLight";
Font-Weight:600;
Font-Size:14px;
    order: 0;
    position: absolute;
    left: 72px;
    align-self: flex-start;
    color:${(props) => props.theme.colors.black_1000};
  }
  .ant-select .ant-select-selection-item {
    font-family: "NunitoSansLight";
    Font-Weight:600;
    Font-Size:14px;
    color:${(props) => props.theme.colors.black_1000};
  }
  .ant-pagination-prev, .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    background:${(props) => props.theme.colors.brown_200};
}
.ant-pagination .ant-pagination-disabled, .ant-pagination .ant-pagination-disabled:hover {

  background:${(props) => props.theme.colors.white} !important;
}
.ant-pagination .ant-pagination-item-active {
  font-weight: 600 !important;
  font-family: 'NunitoSansLight';
  background-color: #ffffff;
  color:${(props) => props.theme.colors.black_1000} !important;
  border: 1px solid #C1BFBC
  ;
}
.ant-select-selector {
  border: 1px solid #C1BFBC !important;
}

.ant-pagination .ant-pagination-item {
  font-family: 'NunitoSansLight';
  color:#78736D;
  font-weight: 600 !important;
  border: 1px solid #C1BFBC ;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #C1BFBC !important;
}
:where(.css-dev-only-do-not-override-oevy7f).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #C1BFBC;
}
.ant-select-focused .ant-select-selector {
  border-color: #C1BFBC !important;
}
a{
  text-decoration: underline !important; 
}
.status-icon-holder{
height:100%;
display:flex;
align-items:center;
margin-left:8px;
}
th.ant-table-cell.center-align{
  padding:0px;
  text-align:center;
}
td.ant-table-cell.center-align{
padding:3.5px 0px !important;
text-align:center;
}
`;
export const FormWraper = styled.div`
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0px;
  }
  .ant-form-item .ant-form-item-label > label {
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin: 2px 8px 4px 0px;
  }

  .ant-input {
    padding: 9.2px 12px;
    border-radius: 4px;
    border: 1px solid #c1bfbc;
    line-height: 20px;
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${(props) => props.theme.colors.black_1000};
    // width:248px;
    background: ${(props) => props.theme.colors.white};
    ::placeholder {
      font-family: "PopinsMedium";
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: "#A5A19D ";
    }
  }
  .ant-input:hover {
    border: 1px solid #ffad33;
  }
  .ant-input:focus {
    border: 1px solid #c1bfbc;
    color: #1a1a1a;
  }
  .ant-input-status-success {
    border: 1px solid #b56d00;
    color: #1a1a1a;
  }

  // textarea//

  textarea {
    height: 128px;
    border: 1px solid #c1bfbc;
  }
  .ant-textarea:hover {
    border: 1px solid #ffad33;
  }
  .ant-textarea-statu-success {
    border: 1px solid #c1bfbc;
    color: #1a1a1a;
  }
  .ant-textarea:focus {
    border: 1px solid #c1bfbc;
    color: #1a1a1a;
    box-shadow: none;
  }

  // select//
  .ant-select-selector {
    border: 1px solid #c1bfbc !important;
    min-height: 40px;
    margin-bottom: 9px;
  }
  .ant-select-selector:hover {
    border: 1px solid #ffad33 !important;
  }
  .ant-select-statu-success {
    border: 1px solid #b56d00;
    color: #1a1a1a;
  }
  .ant-select-selector:focus {
    border: 1px solid #c1bfbc;
    color: #1a1a1a;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 40px;
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    color: "#A5A19D";
  }
  .ant-select-single {
    height: 40px;
  }
  .ant-select-selector {
    height: 40px !important;
  }
  a {
    color: ${(props) => props.theme.colors.brown_200};
    font-family: "NunitoSansRegular";
    font-weight: 600;
  }

  ////checkbox////////////////////////

  .ant-checkbox-wrapper {
    margin: 8px 0px 0px 15px;
  }
  .checkbox-label {
    display: block;
    width: 100%;
    margin-left: 20px;
  }

  .ant-checkbox-wrapper .ant-checkbox span {
    width: 20px;
    height: 20px;
  }

  ////checkbox////////////////////////

  .ant-checkbox-wrapper {
    margin: 8px 0px 0px 15px;
  }
  .checkbox-label {
    display: block;
    width: 100%;
    margin-left: 20px;
  }

  .ant-checkbox-wrapper .ant-checkbox span {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-inner span {
    display: block;
    border: 4px solid red;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #ff9900;
    background-color: #ff9900;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked:focus .ant-checkbox-inner {
    border-color: #ff9900;
    background-color: #ff9900;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox:hover span {
    border: 1px solid #ff9900;
    background-color: #ffffff;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox span {
    border: 1px solid #ff9900;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-checked:hover .ant-checkbox span {
    border: 1px solid #ff9900;
    background-color: #ff9900;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: #ff9900;
    background-color: #ff9900;
  }
  .ant-checkbox-checked:after {
    border: none !important;
  }
  :where(.css-dev-only-do-not-override-htwhyh).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border-color: #ff9900;
    background-color: #ff9900;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff9900 !important;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colors.brown_200};
    background-color: ${(props) => props.theme.colors.brown_200};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.brown_200};
    background-color: ${(props) => props.theme.colors.brown_200};
  }
  .ant-radio-wrapper {
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black_1000};
  }

  .ant-btn-primary {
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-weight: 700;
    background-color: ${(props) => props.theme.colors.brown_200};
    color: ${(props) => props.theme.colors.white};
    padding: 12px 32px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
    height: fit-content;
    line-height: 16px;
    margin: 12px 0px 12px 0px;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.brown_100} !important;
      color: ${(props) => props.theme.colors.white};
    }
  }

  .search-butn {
    font-family: "NunitoSansBold";
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black_1000};
    padding: 11.2px 22px;
    border: 1px solid #c1bfbc;
    border-radius: 4px;
    box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
    height: fit-content;
    line-height: 16px;
    @media (max-width: 500px) {
      width: fit-content;
      margin: auto;
    }
    &:hover {
      color: ${(props) => props.theme.colors.black_1000};
      border: 1px solid #c1bfbc;
    }
    &:focus {
      color: ${(props) => props.theme.colors.black_1000};
      border: 1px solid #c1bfbc;
    }
  }

  .agent {
    padding: 16px;
    background: #d5d4d2;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  .custom-label-psusa {
    font-family: "NunitoSansLight";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    display: block;
    margin: 3px 8px 0px 0px !important;
  }
  .stats-and-display {
    margin: 32px 0px 20px 0px;
    font-family: "NunitoSansRegular";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 176px;
    border-radius: 0px 0px 4px 4px;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background-color: #f2f1f0;
  }
  .ck-powered-by {
    display: none !important;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border-radius: 4px 4px 0px 0px;
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border-color: ${(props) => props.theme.colors.black_400};
  }
  .ck.ck-editor__editable:hovere {
    border-color: ${(props) => props.theme.colors.brown_200};
  }
  .ck .ck-placeholder {
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    color: #a5a19d;
  }

  .ant-picker .ant-picker-input {
    min-height: 31.4px;
    font-family: "PopinsMedium";
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.black_1000};
  }
  .ant-picker-outlined {
    border: 1px solid #c1bfbc;
    border-radius: 4px;
    :hover {
      border: 1px solid ${(props) => props.theme.colors.brown_200};
    }
  }
`;
export const ModalWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  max-width: 400px;
  gap: 16px;
  .mdRegular {
    margin: 0px;
  }
  .ant-modal-root .ant-modal-centered .ant-modal {
    text-align: center;
  }
  .ant-modal.model-grid {
    width: 400px !important;
    text-align: center;
  }
`;


