import React, { useEffect, useState } from "react";
import Paragraph from "src/Components/Shared/Peragraph";
import SubHeading from "src/Components/Shared/SubHeading";
import { FormWraper } from "src/Styles/global";
import { Button, Col, Form, Image, Input, Row, Select } from "antd";
import AuthHeading from "src/Components/Shared/AuthHeading";
import { Link } from "react-router-dom";
import WarningIcon from "./../../../Assets/Icons/WarningIcon";
import NotificationModal from "src/Components/Pages/Auth/NotificationModal";
import ErrorMessage from "src/Components/Shared/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/Components/Constant/routes";
import { LoginAction } from "src/Redux/App/Actions/Auth";
import { loading as stateLoading } from "src/Redux/App";

const { Option } = Select;

interface FormValues {
  users_email: string;
  users_password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const [isModalVisible, setModalVisible] = useState(false);
  const [content, setContent] = useState("Please fill in all required fields.");
  const closeModal = () => {
    setModalVisible(false);
  };
  const onFinishFailed = () => {
    setModalVisible(true);
  };

  const onFinishLogin = (values: any) => {
    dispatch<any>(LoginAction(values, navigate));
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(AppRoutes.home);
    } else {
      navigate(AppRoutes.login);
    }
  }, [1000]);

  return (
    <>
      {localStorage.getItem("token") ? (
        <></>
      ) : (
        <Row justify="space-between">
          <Col xs={22} sm={22} md={20} lg={12} xl={10} xxl={10} id="login">
            <>
              <SubHeading>Admin Dashboard</SubHeading>
              <AuthHeading>Login to Admin Dashboard</AuthHeading>

              {/* login form */}
              <FormWraper>
                <Form
                  layout="vertical"
                  onFinishFailed={onFinishFailed}
                  onFinish={onFinishLogin}
                >
                  <Form.Item
                    name="users_email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message="Please input your email address" />
                        ),
                      },
                    ]}
                  >
                    <Input type={"email"} />
                  </Form.Item>
                  <Form.Item
                    name="users_password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message="Please input your password" />
                        ),
                      },
                    ]}
                  >
                    <Input type={"password"} />
                  </Form.Item>
                  {/* <ReCAPTCHA sitekey="6LeWcV8pAAAAAIV_WqVwjU3icg5DvZ7sKiAkICI2" /> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      SUBMIT
                    </Button>
                  </Form.Item>
                  <Paragraph className="mdRegular">
                    Lost your password?{" "}
                    <Link to="/forgotpassword">CLICK HERE</Link>
                  </Paragraph>
                </Form>
                {/* nitification model */}
                <NotificationModal
                  visible={isModalVisible}
                  onClose={closeModal}
                  content={content}
                  icon={<WarningIcon />}
                />
              </FormWraper>
            </>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Login;
