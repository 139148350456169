import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Image,
  Checkbox,
  Table,
} from 'antd';
import getLocations from 'src/Assets/Content/statesFn';
import type { DatePickerProps, FormInstance } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import ErrorMessage from 'src/Components/Shared/ErrorMessage';
import SubHeading from 'src/Components/Shared/SubHeading';
import { FormWraper } from 'src/Styles/global';
import {
  BoldOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useRef, useState, ChangeEvent, useEffect } from 'react';
import Print from 'src/Assets/Icons/print-icon.svg';
import Trash from 'src/Assets/Icons/trash.svg';
import DropDownIcon from 'src/Assets/Icons/DropDown';
import CalenderIcon from 'src/Assets/Icons/Calender';
import Modal from 'antd/es/modal/Modal';
import FormActionButn from 'src/Components/Shared/FormActionButn';
import FormItem from 'antd/es/form/FormItem';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation } from 'react-router-dom';
import {
  AddApplication,
  SearchJobAction,
  VarifyJobAction,
} from 'src/Redux/App/Actions/Jobs';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RecruitmentWrapper } from '../Recruitment/styles';
import { loading as stateLoading } from 'src/Redux/App';

import axios from 'axios';
import WarningIcon from 'src/Assets/Icons/WarningIcon';
import NotificationModal from 'src/Components/Pages/Auth/NotificationModal';
interface PackageData {
  id: number;
  title: string;
  price: number;
  discription: string;
}
const ApplicationDetails = () => {
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null!);
  const locations: any[] = getLocations();
  const location: any = useLocation();
  const loading = useSelector(stateLoading);
  const record = location.state && location.state.record;
  const [content, setContent] = useState<string | null>('');
  const [notificationContant, setNotificationContant] = useState<string | null>(
    ''
  );

  const [agentsData, setAgentData] = useState({
    agentData: {
      agent: '',
      contactPerson: '',
      agentAddress: '',
      agentState: '',
      agentCity: '',
      zipCode: '',
      contactPhone: '',
      contactEmail: '',
    },
    invoiceCopyTo: '',
    PSUSA_status: '',
    resumeTo_PSUSA: false,
    storeDate: '',
  });
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const obj = {
      PSUSA_status: record?.PSUSA_status,
      agentData: record?.agentData,
      invoiceCopyTo: record?.invoiceCopyTo,
      resumeTo_PSUSA: record?.resumeTo_PSUSA,
      storeDate: record?.storeDate,
      submitResume: record?.submitResume,
    };
    setAgentData(obj);
    setCheckedItems(
      Object.keys(record).length === 0 ? defaultItems : record?.diaplayItem
    );
  }, [1000]);
   console.log(record)
  const [job, setJob] = useState(record);
  const [remoteWorkPermitted, setRemoteWorkPermitted] = useState(false);
  const [editorContent, setEditorContent] = useState<string[]>(['', '']);
  const [value, setValue] = useState(1);
  const defaultItems = {
    salary: true,
    jobNumber: true,
    companyName: true,
    discription: true,
    noOfEmployee: true,
    submitResume: true,
    companyNature: true,
    recruiterFirstName: true,
    recruiterLastName: true,
    multiPosition: true,
    specialSkills: true,
    telecommuting: true,
    worksiteStreet: true,
    travelRequirements: true,
    specialInstructions: true,
    educationAndExperience: true,
  };
  const [checkedItems, setCheckedItems] = useState(
    Object.keys(record).length === 0 ? defaultItems : record?.displayItem
  );
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    setAgentData({ ...agentsData, resumeTo_PSUSA: e.target.value });
  };
  const editorRefs: React.RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;

    setCheckedItems((checkedItems: any) => {
      // Check if the checkbox is checked or unchecked
      if (checked) {
        // If checked, add the key to the data with value true
        console.log(checkedItems, 'checkedItems');
        return { ...checkedItems, [name]: true };
      } else {
        // If unchecked, remove the key from the data
        const newData: any = { ...checkedItems };
        delete newData[name];
        return newData;
      }
    });
  };
  const { Option } = Select;
  const handleCKEditorChange1 = (event: any, editor: any) => {
    const data = editor.getData(); // Get the content from the CKEditor
    setJob({ ...job, discription: data }); // Update the state with the new content
  };
  const handleCKEditorChange2 = (event: any, editor: any) => {
    const data = editor.getData(); // Get the content from the CKEditor
    setJob({ ...job, educationAndExperience: data }); // Update the state with the new content
  };
  const varifyData = () => {
    const data: any = {
      jobTitle: job?.jobTitle,
      jobNumber: job?.jobNumber,
      multiPosition: job?.multiPosition,
      discription: job?.discription,
      educationAndExperience: job?.educationAndExperience,
      requiredSkills: job?.requiredSkills,
      travelRequirements: job?.travelRequirements,
      telecommuting: job?.telecommuting,
      salary: job?.salary,
      specialSkills: job?.specialSkills,
      telecommutingCondition: job?.telecommuting
        ? job?.telecommutingCondition
        : '',
      employerInfo: {
        companyName: job?.employerInfo?.companyName,
        noOfEmployee: job?.employerInfo?.noOfEmployee,
        recruiterFirstName: job?.employerInfo?.recruiterFirstName,
        recruiterLastName: job?.employerInfo?.recruiterLastName,
        hiringManagerTitle: job?.employerInfo?.hiringManagerTitle,
        companyNature: job?.employerInfo?.companyNature,
        worksiteStreet: job?.employerInfo?.worksiteStreet,
        city: job?.employerInfo?.city,
        state: job?.employerInfo?.state,
        worksiteZipCode: job?.employerInfo?.worksiteZipCode,
      },
      specialInstructions: job?.specialInstructions,
      referenceCode: job?.referenceCode,
      recruitmentFirm: job?.recruitmentFirm,
      startDate: job?.startDate,
      jobDuration: job?.jobDuration,
      endDate: job?.endDate,
    };
    console.log(job.resumeTo_PSUSA, '1st');
    console.log(agentsData.resumeTo_PSUSA, '2nd');
    if (job.resumeTo_PSUSA  !== null && job.resumeTo_PSUSA  !== undefined || job.resumeTo_PSUSA  || agentsData.resumeTo_PSUSA !== null && agentsData.resumeTo_PSUSA  !== undefined || agentsData.resumeTo_PSUSA) {
      if (localStorage.getItem('add')) {
        dispatch<any>(
          AddApplication(
            Object.keys(agentsData)?.length === 0 ? job?.agentData : agentsData,
            data,
            checkedItems,
            navigate
          )
        );
      } else {
        dispatch<any>(
          VarifyJobAction(
            job?.id,
            Object.keys(agentsData)?.length === 0 ? job?.agentData : agentsData,
            data,
            checkedItems,
            navigate
          )
        );
      }
    } else {
      setNotificationContant('Please select option for Submit Resume to PSUSA');
      setModalVisible(true);
    }
  };
  const Discard = () => {
    navigate('/');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PackagesData, setPackagesData] = useState<PackageData[]>([]);
  const [loadingData, setloadingData] = useState(false);
  useEffect(() => {
    if (record?.packagesId) {
      setSelectedValue(record?.packagesId);
    }
    const fetchData = async () => {
      try {
        setloadingData(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pakages/list`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            data: {},
          }
        );
        // Assuming the response data is an array of objects with properties: value, price, and label
        setPackagesData(response.data);
        setloadingData(false);
      } catch (error) {
        setloadingData(false);
        console.error('Error fetching radio data:', error);
      }
    };

    fetchData();
  }, []);

  // @ts-ignore
  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-White' : 'table-row-lite';
  };
  const [selectedValue, setSelectedValue] = useState(
    record?.packagesId ? record?.packagesId : null
  );
  const handleRadioChange = (e: any) => {
    localStorage.setItem(
      'pakageId',
      String(PackagesData[e.target.value - 1].id)
    );
    setSelectedValue(e.target.value);
  };
  const closeModal = () => {
    setModalVisible(false);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setNotificationContant('Please fill all required fields');
    setModalVisible(true);
  };
  return (
    <>
      <RecruitmentWrapper>
        <NotificationModal
          visible={isModalVisible}
          onClose={closeModal}
          content={notificationContant}
          icon={<WarningIcon />}
        />
        <Row>
          <Col
            xs={20}
            sm={20}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
          >
            <SubHeading>Recruitment Packages</SubHeading>
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              gap: '8px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <FormActionButn>
                <Image
                  src={Print}
                  preview={false}
                  className='print-icon'
                  onClick={() => window.print()}
                  style={{
                    marginTop: '2px',
                  }}
                />
              </FormActionButn>
              <FormActionButn>
                <Image
                  src={Trash}
                  preview={false}
                  className='print-icon'
                />
              </FormActionButn>
            </div>
          </Col>
          <div className='Radio-wrapper'>
            <Radio.Group
              onChange={handleRadioChange}
              value={selectedValue}
            >
              <Space direction='vertical'>
                {PackagesData.map((option) => (
                  <Radio
                    key={option.id}
                    value={option.id}
                  >
                    <span className='price'>${option.price}</span>
                    <span className='package-heading'>{option.title}: </span>
                    <span className='label'>{option.discription}</span>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </Row>
      </RecruitmentWrapper>

      <Row justify={'space-between'}>
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={20}
          xl={20}
          xxl={20}
        >
          <SubHeading>Client Application Details</SubHeading>
        </Col>
      </Row>
      <Row>
        <Col md={16}>
          <FormWraper>
            <Form
              layout='vertical'
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              onFinish={varifyData}
              ref={(form) => {
                formRef.current = form!;
              }}
            >
              {/* Job Title */}
              <Form.Item
                label='Job Title'
                name='jobTitle'
                rules={[
                  {
                    required: job?.jobTitle ? false : true,
                    message: <ErrorMessage message='Please input Job Title' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.jobTitle}
                      defaultValue={job?.jobTitle}
                      onChange={(e) =>
                        setJob({ ...job, jobTitle: e.target.value })
                      }
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Client Job Number */}
              <Form.Item
                label='Client Job Number'
                name='jobNumber'
                rules={[
                  {
                    required: job?.jobNumber ? false : true,
                    message: <ErrorMessage message='Please input Job Number' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      placeholder=''
                      value={job?.jobNumber}
                      onChange={(e) =>
                        setJob({ ...job, jobNumber: e.target.value })
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.jobNumber}
                      name='jobNumber'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Multiple Positions Available? */}
              <Form.Item
                label='Multiple Positions Available?'
                name='multiPosition'
                rules={[
                  {
                    required: job?.multiPosition ? false : true,
                    message: (
                      <ErrorMessage message='Please choose a position' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      defaultValue={job?.multiPosition ? 'Yes' : 'No'}
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) =>
                        setJob({ ...job, multiPosition: Boolean(value) })
                      }
                    >
                      <Option value='false'>No</Option>
                      <Option value='true'>Yes</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.multiPosition ?? false}
                      name='multiPosition'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Job Description */}
              <Form.Item label='Job Description'>
                <Row>
                  <Col span={23}>
                    <CKEditor
                      data={job?.discription}
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        // console.log(
                        //   "CKEditor5 React Component is ready to use!",
                        //   editor
                        // );
                      }}
                      onChange={handleCKEditorChange1}
                      config={{
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.discription ?? false}
                      name='discription'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Education and Experience Requirements */}
              <Form.Item label='Education and Experience Requirements'>
                <Row>
                  <Col span={23}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={job?.educationAndExperience}
                      onReady={(editor) => {
                        // console.log(
                        //   "CKEditor5 React Component is ready to use!",
                        //   editor
                        // );
                      }}
                      onChange={handleCKEditorChange2}
                      config={{
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.educationAndExperience ?? false}
                      name='educationAndExperience'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Special Skills Requirements */}
              <Form.Item
                label='Special Skills Requirements'
                name='skills_requirements'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put your skills requirements' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.specialSkills}
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          specialSkills: e.target.value,
                        }))
                      }
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.specialSkills ?? false}
                      name='specialSkills'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Travel Requirements */}
              <Form.Item
                label='Travel Requirements'
                name='travel_requirements'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put your Travel requirements' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.travelRequirements}
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          travelRequirements: e.target.value,
                        }))
                      }
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.travelRequirements ?? false}
                      name='travelRequirements'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Telecommuting/Remote Work Permitted? */}
              <Form.Item
                label='Telecommuting/Remote Work Permitted?'
                name='telecommuting'
                rules={[
                  {
                    required: job?.telecommuting === undefined ? true : false,
                    message: (
                      <ErrorMessage message='Please choose a telecommuting/remote work permitted' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        console.log(value);
                        setJob({ ...job, telecommuting: value });
                      }}
                      value={
                        job?.telecommuting
                          ? 'Yes'
                          : job?.telecommuting === undefined
                          ? ''
                          : 'No'
                      }
                      // onChange={handleCKEditorChange2}
                    >
                      <Option value={false}>No</Option>
                      <Option value={true}>Yes</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Col span={1}>
                      <Checkbox
                        checked={checkedItems?.telecommuting ?? false}
                        name='telecommuting'
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Col>
                </Row>
              </Form.Item>
              {/* Telecommuting/Remote Work Conditions */}
              {job?.telecommuting ? (
                <Form.Item
                  label='Telecommuting/Remote Work Conditions'
                  name='Remote_work_conditions'
                  rules={[
                    {
                      required: job?.telecommuting ? false : true,
                      message: (
                        <ErrorMessage message='Please choose Telecommuting/Remote Work Conditions' />
                      ),
                    },
                  ]}
                >
                  <Row>
                    <Col span={23}>
                      <Input
                        value={job?.telecommutingCondition}
                        onChange={(e) => {
                          setJob({
                            ...job,
                            telecommutingCondition: e.target.value,
                          });
                        }}
                        placeholder='e.g., 2-3 days per week, reside in the same metro area, etc.'
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                ''
              )}
              {/* Salary */}
              <Form.Item
                label='Salary'
                name='salary'
                rules={[
                  {
                    required: job?.salary ? false : true,
                    message: <ErrorMessage message='Please put salary' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='text'
                      name='salary'
                      value={job?.salary ? job?.salary : ''}
                      onChange={(e) =>
                        setJob({ ...job, salary: e.target.value })
                      }
                      placeholder={
                        !job?.salary || job?.salary == 0
                          ? 'Not Provided'
                          : job?.salary
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.salary ?? false}
                      name='salary'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Name of Company */}
              <Form.Item
                label='Name of Company'
                name='companyName'
                rules={[
                  {
                    required: job?.employerInfo?.companyName ? false : true,
                    message: (
                      <ErrorMessage message='Please Put Name of company' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.companyName}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            companyName: e.target.value,
                          },
                        })
                      }
                      placeholder='Jade Century Properties Inc.'
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.companyName ?? false}
                      name='companyName'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Number of Employees */}
              <Form.Item
                label='Number of Employees'
                name='number_of_employees'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please choose Number of Employees' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) =>
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            noOfEmployee: value,
                          },
                        })
                      }
                      value={job?.employerInfo?.noOfEmployee}
                    >
                      <Option value='1 to 3'>1-3</Option>
                      <Option value='12 to 15'>4-14</Option>
                      <Option value='16 to 49'>16-49</Option>
                      <Option value='50'>50+</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.noOfEmployee ?? false}
                      name='noOfEmployee'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* First Name of Hiring Manager */}
              <Form.Item
                label='First Name of Hiring Manager'
                name='recruiterFirstName'
                rules={[
                  {
                    required: job?.employerInfo?.recruiterFirstName
                      ? false
                      : true,
                    message: (
                      <ErrorMessage message='Please put First Name of Hiring Manager' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.recruiterFirstName}
                      name='recruiterFirstName'
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            recruiterFirstName: e.target.value,
                          },
                        });
                      }}
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.recruiterFirstName ?? false}
                      name='recruiterFirstName'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Last Name of Hiring Manager */}
              <Form.Item
                label='Last Name of Hiring Manager'
                name='recruiterLastName'
                rules={[
                  {
                    required: job?.employerInfo?.recruiterLastName
                      ? false
                      : true,
                    message: (
                      <ErrorMessage message='Please put Last Name of Hiring Manager' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.recruiterLastName}
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            recruiterLastName: e.target.value,
                          },
                        });
                      }}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Title of Hiring Manager */}
              <Form.Item
                label='Title of Hiring Manager'
                name='hiringManagerTitle'
                rules={[
                  {
                    required: job?.employerInfo?.hiringManagerTitle
                      ? false
                      : true,
                    message: (
                      <ErrorMessage message='Please choose Number of Employees' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            hiringManagerTitle: value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.hiringManagerTitle}
                    >
                      <Option value='Mr.'>Mr.</Option>
                      <Option value='Mrs.'>Mrs.</Option>
                      <Option value='Ms.'>Ms.</Option>
                      <Option value='Dr.'>Dr.</Option>
                      <Option value='Other'>Other</Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
              {/* Nature of Company’s Business */}
              <Form.Item
                label='Nature of Company’s Business'
                name='nature_of_company’s_business'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put Nature of Company’s Business' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            companyNature: e.target.value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.companyNature}
                      placeholder='Maximum 55 characters'
                    />
                  </Col>

                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.companyNature ?? false}
                      name='companyNature'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Job Worksite Street Address */}
              <Col span={23}>
                <Form.Item
                  label='City'
                  name='city'
                  rules={[
                    {
                      required: job?.employerInfo?.city ? false : true,
                      message: (
                        <ErrorMessage message='Please input your city' />
                      ),
                    },
                  ]}
                >
                  <Input
                    defaultValue={job?.employerInfo?.city}
                    value={job?.employerInfo?.city}
                    onChange={(e: any) => {
                      setJob({
                        ...job,
                        employerInfo: {
                          ...job?.employerInfo,
                          city: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  label='State'
                  name='state'
                  rules={[
                    {
                      required: job?.employerInfo?.state ? false : true,
                      message: (
                        <ErrorMessage message='Please input your state' />
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    defaultValue={job.employerInfo?.state}
                    value={job.employerInfo?.state}
                    onChange={(value) => {
                      setJob({
                        ...job,
                        employerInfo: {
                          ...job?.employerInfo,
                          state: value,
                        },
                      });
                    }}
                  >
                    {locations.map((location) => (
                      <Option
                        key={location.key}
                        value={location.label}
                        label={location.label}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '13px',
                          }}
                        >
                          {location.label}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Job Worksite Zip Code */}
              <Form.Item
                label='Job Worksite Zip Code'
                name='zip_code'
                rules={[
                  {
                    required: job?.employerInfo?.worksiteZipCode ? false : true,
                    message: (
                      <ErrorMessage message='Please input your job worksite zip code' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            worksiteZipCode: e.target.value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.worksiteZipCode}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Special Instructions */}
              <Form.Item label='Special Instructions'>
                <div>
                  <Row>
                    <Col span={23}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={job?.specialInstructions}
                        onReady={(editor) => {
                          // console.log(
                          //   "CKEditor5 React Component is ready to use!",
                          //   editor
                          // );
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          setJob({
                            ...job,
                            specialInstructions: data,
                          });
                        }}
                        config={{
                          toolbar: {
                            items: [
                              'bold',
                              'italic',
                              '|', // Add a separator after the second button
                              'bulletedList',
                              'numberedList',
                            ],
                            shouldNotGroupWhenFull: true, // Add this to prevent buttons from grouping
                          },
                        }}
                      />
                    </Col>
                    <Col span={1}>
                      <Checkbox
                        checked={checkedItems?.specialInstructions ?? false}
                        name='specialInstructions'
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                </div>
              </Form.Item>
              {/* Your Internal Reference Number/Code */}
              <Form.Item
                label='Your Internal Reference Number/Code'
                name='instructions'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input your special instructions' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          referenceCode: e.target.value, // Update toApplyStatus
                        }))
                      }
                      value={job?.referenceCode}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Law Firm/Consultant Associated with this Recruitment */}
              <Form.Item
                label='Law Firm/Consultant Associated with this Recruitment'
                name='law_firm'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input your law firm' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          recruitmentFirm: e.target.value, // Update toApplyStatus
                        }))
                      }
                      value={job?.recruitmentFirm}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* agent */}
              <Row>
                <Col span={23}>
                  <div className='agent'>
                    <Form.Item
                      label='Agent'
                      name='companyName'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input Agent name' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        defaultValue={job?.employerInfo?.companyName}
                        onChange={(e) => {
                          setJob({
                            ...job,
                            employerInfo: {
                              ...job?.employerInfo,
                              companyName: e.target.value,
                            },
                          });
                        }}
                        name='companyName'
                        placeholder=''
                      />
                    </Form.Item>
                    {/* Contact Person Name */}
                    <Form.Item
                      label='Contact Person Name'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input your law firm' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        defaultValue={job?.agentData?.contactPerson}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              [name]: value,
                            },
                          }));
                        }}
                        name='contactPerson'
                        placeholder=''
                      />
                    </Form.Item>
                    {/* Agent Address */}
                    <Form.Item
                      label='Agent Address'
                      name='agent_address'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input Agent Address' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        defaultValue={job?.agentData?.agentAddress}
                        placeholder=''
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              agentAddress: value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    {/* Agent  City  */}
                    <Form.Item
                      label='City'
                      name='agentCity'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input your city' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        defaultValue={job.agentData?.agentCity}
                        onChange={(e) => {
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              agentCity: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label='State'
                      name='agentState'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input your state' />
                          ),
                        },
                      ]}
                    >
                      <Select
                        onChange={(value) =>
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              agentState: value,
                            },
                          }))
                        }
                        defaultValue={job.agentData?.agentState}
                        showSearch
                      >
                        {locations.map((location) => (
                          <Option
                            key={location.key}
                            value={location.label}
                            label={location.label}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '13px',
                              }}
                            >
                              {/* <LocationIcon /> */}
                              {location.label}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {/* Agent Zip Code */}
                    <Form.Item
                      label='Agent Zip Code'
                      name='agent_zip_code'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input Agent Zip Code' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        defaultValue={job?.agentData?.zipCode}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              [name]: value,
                            },
                          }));
                        }}
                        name='zipCode'
                        placeholder=''
                      />
                    </Form.Item>
                    {/* Contact Phone */}
                    <Form.Item
                      label='Contact Phone'
                      name='contact_phone'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input Contact Phone' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        defaultValue={job?.agentData?.contactPhone}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              [name]: value,
                            },
                          }));
                        }}
                        name='contactPhone'
                        placeholder='22 7134 4664'
                      />
                    </Form.Item>
                    {/* Contact Email */}
                    <Form.Item
                      label='Contact Email'
                      name='contact_email'
                      rules={[
                        {
                          required: false,
                          message: (
                            <ErrorMessage message='Please input Contact Email' />
                          ),
                        },
                      ]}
                    >
                      <Input
                        type='email'
                        defaultValue={job?.agentData?.contactEmail}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setAgentData((agentsData) => ({
                            ...agentsData,
                            agentData: {
                              ...agentsData.agentData,
                              [name]: value,
                            },
                          }));
                        }}
                        name='contactEmail'
                        placeholder='psusa@gmail.com'
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {/* Send Email Invoice Copy to */}
              {/* <Form.Item
                label='Send Email Invoice Copy to'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Email' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='email'
                      defaultValue={job?.invoiceCopyTo}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setAgentData((agentsData) => ({
                          ...agentsData,
                          invoiceCopyTo: value,
                        }));
                      }}
                      name='invoiceCopyTo'
                      placeholder='psusa@gmail.com'
                    />
                  </Col>
                </Row>
              </Form.Item> */}
              {/* Submit Resume to PSUSA */}
              <FormItem>
                <div>
                  <label className='custom-label-psusa'>
                    Submit Resume to PSUSA
                  </label>
                </div>
                <Row>
                  <Col span={23}>
                    <Radio.Group
                      onChange={onChange}
                      defaultValue={job?.resumeTo_PSUSA}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </FormItem>
              {/* Submit Resume to Employer */}
              <Form.Item
                label='Submit Resume to Employer'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Email  to Submit Resume ' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='email'
                      defaultValue={job?.submitResume}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setAgentData((agentsData) => ({
                          ...agentsData,
                          submitResume: value,
                        }));
                      }}
                      name='submitResume'
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.submitResume ?? false}
                      name='submitResume'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Status and Display */}
              <h3 className='stats-and-display'>Status and Display</h3>
              {/* Recruitment Start Date */}
              <Form.Item
                label='Recruitment Start Date'
                name='recruitment_start_date'
                rules={[
                  {
                    required: job.startDate ? false : true,
                    message: (
                      <ErrorMessage message='Please input Recruitment Start Date' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='YYYY-MM-DD'
                      defaultValue={
                        job?.startDate ? dayjs(job.startDate) : null
                      }
                      onChange={(selectedDate) => {
                        console.log(selectedDate, 'selectedDate');
                        setJob({
                          ...job,
                          startDate: selectedDate
                            ? selectedDate.format('YYYY-MM-DD')
                            : null,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Duration of Recruitment */}
              <Form.Item
                label='Duration of Recruitment'
                name='duration_of_recruitment'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Duration of Recruitment ' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      defaultValue={job?.jobDuration}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setJob({
                          ...job,
                          jobDuration: value,
                        });
                      }}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* End Date */}
              <Form.Item
                label='End Date'
                name='recruitment_end_date'
                rules={[
                  {
                    required: job.endDate ? false : true,
                    message: (
                      <ErrorMessage message='Please input Recruitment End Date' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      // value={job.startDate}
                      // defaultValue={job.startDate}
                      style={{ width: '100%' }}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='YYYY-MM-DD'
                      defaultValue={job?.endDate ? dayjs(job.endDate) : null}
                      onChange={(selectedDate) => {
                        setJob({
                          ...job,
                          endDate: selectedDate
                            ? selectedDate.format('YYYY-MM-DD')
                            : null,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Status */}
              <FormItem>
                <div>
                  <label className='custom-label-psusa'>Status</label>
                </div>
                <Row>
                  <Col span={23}>
                    <Radio.Group
                      defaultValue={
                        job?.PSUSA_status == 'Enabled'
                          ? 1
                          : job?.PSUSA_status == 'Disabled'
                          ? 2
                          : 0
                      }
                      onChange={(e) =>
                        setAgentData({
                          ...agentsData,
                          PSUSA_status:
                            e.target.value == 1 ? 'Enabled' : 'Disabled',
                        })
                      }
                    >
                      <Radio value={1}>Enabled</Radio>
                      <Radio value={2}>Disabled</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </FormItem>
              {/* Store Date */}
              <Form.Item
                label='Store Date'
                name='storeDate'
                rules={[
                  {
                    required: agentsData.storeDate ? false : true,
                    message: <ErrorMessage message='Please input Store Date' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='YYYY-MM-DD'
                      defaultValue={
                        job?.startDate ? dayjs(job.storeDate) : null
                      }
                      onChange={(date, dateString) => {
                        console.log(dateString.toString(), 'kkkkkk');
                        setAgentData({
                          ...agentsData,
                          storeDate: dateString.toString(),
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* save */}

              <Button
                loading={loading}
                type='primary'
                htmlType='submit'
              >
                Save
              </Button>
              <Button
                style={{ position: 'relative', left: '5px' }}
                onClick={() => Discard()}
                className='search-butn'
                htmlType='submit'
              >
                Cancel
              </Button>
            </Form>
          </FormWraper>
        </Col>
      </Row>
    </>
  );
};

export default ApplicationDetails;
